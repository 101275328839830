'use strict';

Gri.module({
  name: 'carousel-alpha',
  ieVersion: null,
  $el: $('.carousel-alpha'),
  container: '.carousel-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    // endregion

    // region run
    $this.owlCarousel({
      items: 1,
      dots: true
    });
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion


    // IF THE BROWSER IS INTERNET EXPLORER 11 Remove Carousel Light Effects //
    var UAString = navigator.userAgent;
    if (UAString.indexOf("Trident") !== -1 && UAString.indexOf("rv:11") !== -1)
    {
        $('[carouselEffect]').remove();
    }
    // IF THE BROWSER IS INTERNET EXPLORER 11 Remove Carousel Light Effects //

  }
});
